import React from "react";
import { HeaderPhoneUi } from "../../../../../components/ui";
import { IconInfo } from "../../../../../utils/Icons";
import withPurchase from "../../../../../hoc/withPurchase";
import { formattedNumber } from "../../../../../utils/formattedNumber";

function Result({ type, state, mobile, btc, general }) {
  return (
    <>
      <img
        src={require(`../../../../../assets/img/generator/exchange/bybit/wallet/assets/bybit-assets-${type}-${state.theme}.png`)}
        className="img-fluid img-thumbnail"
        width="100%"
        alt=""
      />

      <HeaderPhoneUi
        type={type}
        mode={state.theme}
        {...mobile}
        colorBattery={state.theme === "light" ? "#fff" : "#17171a"}
      />

      <div className="general-assets">
        <div className="value">
          <div className="value-numb">
            {formattedNumber(general || "0.00", { min: 2, locale: "en" })}
            <div className="usd-currency">USD</div>
          </div>
        </div>

        <div className="btc">
          ≈ {isNaN(btc) ? "0.00000000" : btc} BTC
          <IconInfo fill="#81858c" className="icon" width="34px" />
        </div>
      </div>

      <div className="my-accounts">
        <div className="value">
          {formattedNumber(state?.assetsFinancing || "0.00", {
            min: 2,
            locale: "en",
          })}
          <span className="currency">USD</span>
        </div>
        <div className="value">
          {formattedNumber(state?.assetsSingle || "0.00", {
            min: 2,
            locale: "en",
          })}
          <span className="currency">USD</span>
        </div>
      </div>
    </>
  );
}

export default withPurchase(Result, {
  className: "result-img bybit wallet assets",
});
