import React from "react";
import dayjs from "dayjs";
import withPurchase from "../../../../../hoc/withPurchase";
import { HeaderPhoneUi } from "../../../../../components/ui";

function Result({ type, state, mobile }) {
  const customNetwork = state?.network === "TRC-20" ? "TRC20" : state.network;

  return (
    <>
      <img
        src={require(`../../../../../assets/img/generator/exchange/bybit/mail/withdrawal/bybit-withdrawal-${type}-${state.theme}.png`)}
        className="img-fluid img-thumbnail"
        width="100%"
        alt=""
      />

      <HeaderPhoneUi
        type={type}
        mode={state.theme}
        {...mobile}
        colorBattery={state.theme === "light" ? "#fff" : "#202124"}
      />

      <div className="details">
        <div className="time">
          {state?.time ? dayjs(state?.time).format("HH:mm") : "00:00"}
        </div>
        <div className="amount">
          Вы успешно вывели {state?.amount || 0} {state?.currency} с Вашего
          Bybit аккаунта.
        </div>
        <div className="network">{customNetwork || "______"}</div>
        <div className="address">{state?.address || "_______________"}</div>
        <div className="hash">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {state?.hash || "_______________"}
        </div>
      </div>
    </>
  );
}

export default withPurchase(Result, {
  className: "result-img bybit mail withdrawal",
});
