export const CRYPTOCURRENCY_ARRAY = [
  {
    key: "tether",
    name: "Tether (USDT)",
    title: "Tether",
    coin: "TRC20",
    value: "USDT",
    color: "#53ae94",
    network: "Tron",
    chain: true,
    description:
      "Tether is multi-chain stablecoin. Each USDT token is a designed to be backed by U.S. Dollar held in banking reserves.",
    icon_binance: require("../assets/img/cryptocurrency/binance/usdt.png"),
    icon_okx: require("../assets/img/cryptocurrency/okx/usdt.svg").default,
    icon_trust: require("../assets/img/cryptocurrency/trust/usdt.svg").default,
    icon_bybit: require("../assets/img/cryptocurrency/bybit/usdt.png"),
    icon_exodus: require("../assets/img/cryptocurrency/exodus/usdt.svg")
      .default,
  },
  {
    key: "bitcoin",
    name: "Bitcoin (BTC)",
    title: "Bitcoin",
    coin: "COIN",
    value: "BTC",
    color: "#ffc82d",
    network: false,
    chain: false,
    description:
      'The cryptocurrency that started it all, Bitcoin is the first digital currency to solve the "double spending" or counterfeiting problem without the aid of a central authority, such as a bank or a government, making Bitcoin truly peer-to-peer.',
    icon_binance: require("../assets/img/cryptocurrency/binance/btc.png"),
    icon_okx: require("../assets/img/cryptocurrency/okx/btc.svg").default,
    icon_trust: require("../assets/img/cryptocurrency/trust/btc.svg").default,
    icon_bybit: require("../assets/img/cryptocurrency/bybit/btc.svg").default,
    icon_exodus: require("../assets/img/cryptocurrency/exodus/btc.svg").default,
    icon_bybit_dark: require("../assets/img/cryptocurrency/bybit/btc_dark.svg")
      .default,
  },
  {
    key: "ethereum",
    name: "Ethereum (ETH)",
    title: "Ethereum",
    coin: "COIN",
    value: "ETH",
    color: "#8c93af",
    network: false,
    chain: true,
    description:
      "Ethereum is a decentralized computing platform that runs smart contracts, which are contracts that execute without human intervention. ETH popularized the idea of using the blockchain for programmable transactions instead of only for money transfers. The platform is used for crowdfunding (ICOs), the creation of new digital assets, and more.",
    icon_binance: require("../assets/img/cryptocurrency/binance/eth.png"),
    icon_okx: require("../assets/img/cryptocurrency/okx/eth.svg").default,
    icon_trust: require("../assets/img/cryptocurrency/trust/eth.svg").default,
    icon_bybit: require("../assets/img/cryptocurrency/bybit/eth.svg").default,
    icon_exodus: require("../assets/img/cryptocurrency/exodus/eth.svg").default,
    icon_bybit_dark: require("../assets/img/cryptocurrency/bybit/eth_dark.svg")
      .default,
  },
  {
    key: "litecoin",
    name: "Litecoin (LTC)",
    title: "Litecoin",
    coin: "COIN",
    value: "LTC",
    color: "#d6d6d6",
    network: false,
    chain: false,
    description:
      "Litecoin is a cryptocurrency similar to Bitcoin. The goal of Litecoin is to provide fast transaction confirmations. Created by ex-Google employee Charlie Lee, Litecoin is often considered the silver to Bitcoin's gold.",
    icon_binance: require("../assets/img/cryptocurrency/binance/ltc.png"),
    icon_trust: require("../assets/img/cryptocurrency/trust/ltc.svg").default,
    icon_bybit: require("../assets/img/cryptocurrency/bybit/ltc.svg").default,
    icon_exodus: require("../assets/img/cryptocurrency/exodus/ltc.svg").default,
    icon_bybit_dark: require("../assets/img/cryptocurrency/bybit/ltc_dark.svg")
      .default,
  },
  {
    key: "tron",
    name: "Tron (TRX)",
    title: "Tron",
    coin: "COIN",
    value: "TRX",
    color: "#d11e25",
    network: false,
    chain: false,
    description:
      "TRON is a cryptocurrency dedicated to building the infrastructure for a decentralized Internet.",
    icon_binance: require("../assets/img/cryptocurrency/binance/trx.png"),
    icon_trust: require("../assets/img/cryptocurrency/trust/trx.svg").default,
    icon_bybit: require("../assets/img/cryptocurrency/bybit/trx.svg").default,
    icon_exodus: require("../assets/img/cryptocurrency/exodus/trx.svg").default,
  },
  {
    key: "dogecoin",
    name: "Dogecoin (DOGE)",
    title: "Dogecoin",
    coin: "COIN",
    value: "DOGE",
    color: "#a8a27f",
    network: false,
    chain: false,
    description:
      "Dogecoin is a decentralized, peer-to-peer digital currency that enables you to easily send money online.",
    icon_binance: require("../assets/img/cryptocurrency/binance/doge.png"),
    icon_trust: require("../assets/img/cryptocurrency/trust/doge.svg").default,
    icon_bybit: require("../assets/img/cryptocurrency/bybit/doge.svg").default,
    icon_exodus: require("../assets/img/cryptocurrency/exodus/doge.svg")
      .default,
  },
  {
    key: "bnb",
    name: "BNB Smart Chain (BNB)",
    title: "BNB Smart Chain",
    coin: "COIN",
    value: "BNB",
    color: "#ffb700",
    network: false,
    chain: true,
    description:
      "BNB Smart Chain (BSC) is an innovative solution to bring programmability and interoperability to Beacon Chain. The BNB Smart Chain also supports EVM-compatible smart contracts and protocols. Cross-chain transfer and other communication are possible due to native support of interoperability.",
    icon_binance: require("../assets/img/cryptocurrency/binance/bnb.svg")
      .default,
    icon_trust: require("../assets/img/cryptocurrency/trust/bnb.svg").default,
    icon_bybit: require("../assets/img/cryptocurrency/bybit/bnb.svg").default,
    icon_exodus: require("../assets/img/cryptocurrency/exodus/bnb.svg").default,
  },
];
