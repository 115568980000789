import React from "react";
import dayjs from "dayjs";
import withPurchase from "../../../../../hoc/withPurchase";
import { HeaderPhoneUi } from "../../../../../components/ui";

function Result({ type, state, mobile }) {
  const customNetwork = state?.network === "TRC-20" ? "TRC20" : state.network;

  return (
    <>
      <img
        src={require(`../../../../../assets/img/generator/exchange/bybit/mail/deposit/bybit-deposit-${type}-${state.theme}.png`)}
        className="img-fluid img-thumbnail"
        width="100%"
        alt=""
      />

      <HeaderPhoneUi
        type={type}
        mode={state.theme}
        {...mobile}
        colorBattery={state.theme === "light" ? "#fff" : "#202124"}
      />

      <div className="details">
        <div className="time">
          {state?.time ? dayjs(state?.time).format("HH:mm") : "00:00"}
        </div>
        <div className="amount">
          {state?.amount || 0} {state?.currency}
        </div>
        <div className="network">{customNetwork || "______"}</div>
        <div className="address">{state?.address || "_______________"}</div>
        <div className="date">
          {state?.date
            ? `${dayjs(state?.date).format("YYYY-MM-DD H:mm:ss")}(UTC)`
            : "2024-01-01 00:00:00"}
        </div>
      </div>
    </>
  );
}

export default withPurchase(Result, {
  className: "result-img bybit mail deposit",
});
