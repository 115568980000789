import {SBERBANK_ARRAY, ALFA_ARRAY} from '../constants/BankArray';
import { TRUST_ARRAY, EXODUS_ARRAY } from '../constants/WalletsArray';
import { BINANCE_ARRAY, BYBIT_ARRAY, OKX_ARRAY, HTX_ARRAY } from '../constants/ExchangeArray';

export const SUBSECTIONS_ENUM = {
    Binance: "Binance",
    ByBit: "ByBit",
    OKX: "OKX",
    HTX: "HTX",
    TrustWallet: "Trust Wallet",
    Exodus: "Exodus",
    Sberbank: "Sberbank",
    AlfaBank: "Alfa Bank",
};

export const SUBSECTIONS_DOCS = {
    "Binance": BINANCE_ARRAY,
    "ByBit": BYBIT_ARRAY,
    "OKX": OKX_ARRAY,
    "HTX": HTX_ARRAY,
    "Trust Wallet": TRUST_ARRAY,
    "Exodus": EXODUS_ARRAY,
    "Sberbank": SBERBANK_ARRAY,
    "Alfa Bank": ALFA_ARRAY,
    "All": [...BINANCE_ARRAY, ...BYBIT_ARRAY, ...OKX_ARRAY, ...HTX_ARRAY, ...TRUST_ARRAY, ...EXODUS_ARRAY, ...SBERBANK_ARRAY, ...ALFA_ARRAY]
};
  
export const SUBSECTIONS = Object.keys(SUBSECTIONS_DOCS);