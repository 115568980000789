import React from "react";
import dayjs from "dayjs";
import withPurchase from "../../../../../hoc/withPurchase";
import { HeaderPhoneUi } from "../../../../../components/ui";
import { formattedNumber } from "../../../../../utils/formattedNumber";

function Result({ type, state, mobile }) {
  const customNetwork = state.network === "TRC-20" ? "TRC20" : state.network;

  return (
    <>
      <img
        src={require(`../../../../../assets/img/generator/exchange/bybit/wallet/deposit/bybit-deposit-details-${type}-${state.theme}.png`)}
        className="img-fluid img-thumbnail"
        width="100%"
        alt=""
      />

      <HeaderPhoneUi
        type={type}
        mode={state.theme}
        {...mobile}
        colorBattery={state.theme === "light" ? "#fff" : "#0f1014"}
      />

      <div className="amount">
        {formattedNumber(state?.amount || 0, { locale: "en" })}{" "}
        {state?.currency || "USDT"}
      </div>
      <div className="details">
        <div className="account">
          {state?.depositAccount || "_________________"}
        </div>
        <div className="network">{customNetwork || "______"}</div>
        <div className="time">
          {state?.date
            ? dayjs(state?.date).format("YYYY-MM-DD H:mm:ss")
            : "2024-01-01 00:00:00"}
        </div>
        <div className="address">{state?.address || "_______________"}</div>
        <div className="hash">{state?.hash || "_______________"}</div>
      </div>
    </>
  );
}

export default withPurchase(Result, {
  className: "result-img bybit wallet deposit",
});
